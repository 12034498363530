import React from 'react';
import Layout from '../../components/Layout';
import CostSupport, {
  costAndSupportBody
} from '../../components/CostSupport/CostSupport';
import Hero2, { costAndSupport } from '../../components/Hero/Hero2';
import TwoColor, {
  costAndSupportTwoColor
} from '../../components/TwoColor/TwoColor';

// import CostAndSupportHero from '../../components/Hero/cost-and-support';
// import Pay from '../../components/Pay/pay';
// import Enrollment from '../../components/Enrollment/enrollment';
// import CopayTerms from '../../components/CopayTerms/copay-terms';

import GlobalFaq from '../../components/GlobalFaq/global-faq';

const Home = () => {
  return (
    <Layout section="EM" page="/endometriosis/faq/">
      <>
        <GlobalFaq />
        {/* <Hero2 {...costAndSupport} />
        <TwoColor {...costAndSupportTwoColor} /> */}

        {/* <CostAndSupportHero /> */}

        {/* <Pay /> */}

        {/* <Enrollment /> */}

        {/* {costAndSupportBody.map((costAndSupportProps, index) => (
          <CostSupport {...costAndSupportProps} key={index} />
        ))} */}

        {/* <CopayTerms /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/endometriosis/faq/';
  const title =
    'Support for MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 0.4mg, 1mg, 0.5mg Tablets for Endometriosis Pain';
  const pageTitle =
    'Support for MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 0.4mg, 1mg, 0.5mg Tablets for Endometriosis Pain';
  const description =
    'Find cost savings and co-pay card info for MYFEMBREE® used for moderate to severe endometriosis pain in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
